/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TypeGradientWrapper = ({ type, className, typeLine = "/img/type-line.svg" }) => {
  return (
    <img
      className={`type-gradient-wrapper ${type} ${className}`}
      alt="Type gradient"
      src={type === "line" ? typeLine : "/img/type-gradient.svg"}
    />
  );
};

TypeGradientWrapper.propTypes = {
  type: PropTypes.oneOf(["gradient", "line"]),
  typeLine: PropTypes.string,
};
