import React from "react";
import { ButtonSpecial } from "../../components/ButtonSpecial";
import { Divider } from "../../components/Divider";
import { IcAccount } from "../../components/IcAccount";
import { IcArrowDown } from "../../components/IcArrowDown";
import { IcArrowDownWrapper } from "../../components/IcArrowDownWrapper";
import { TypeGradientWrapper } from "../../components/TypeGradientWrapper";
import "./style.css";

export const CompaniansLanding = () => {
  return (
    <div className="companians-landing">
      <p className="h-ng-l-n-mu-n-i-ch-i">
        Hứng lên muốn đi chơi?
        <br />
        Nhưng không biết rủ ai?
      </p>
      <div className="text-wrapper">Nếu bạn định hỏi</div>
      <div className="group">
        <div className="group-2">
          <p className="p">1. Làm thế nào để bắt đầu sử dụng Companians?</p>
          <Divider className="divider-instance" />
          <p className="text-wrapper-2">2. Tôi có thể tìm bạn cho hoạt động nào trên Companians?</p>
          <Divider className="design-component-instance-node" />
          <div className="group-wrapper">
            <div className="ic-arrow-down-instance-wrapper">
              <IcArrowDown className="ic-arrow-down-instance" />
            </div>
          </div>
          <div className="div-wrapper">
            <div className="ic-arrow-down-instance-wrapper">
              <IcArrowDown className="ic-arrow-down-2" />
            </div>
          </div>
          <Divider className="divider-2" />
          <p className="element-t-i-l-m-th-n-o-n-u">
            5. Tôi làm thế nào nếu gặp phải sự cố hoặc hành vi không <br />
            &nbsp;&nbsp;&nbsp;&nbsp;phù hợp trên ứng dụng?
          </p>
          <div className="group-3">
            <div className="ic-arrow-down-instance-wrapper">
              <IcArrowDown className="ic-arrow-down-3" />
            </div>
          </div>
          <Divider className="divider-3" />
          <p className="text-wrapper-3">4. Tôi có phải trả phí để sử dụng Companians không?</p>
          <div className="group-4">
            <div className="ic-arrow-down-instance-wrapper">
              <IcArrowDown className="ic-arrow-down-4" />
            </div>
          </div>
          <p className="text-wrapper-4">3. Thông tin của tôi có an toàn khi dùng ứng dụng không?</p>
          <div className="group-5">
            <div className="ic-arrow-down-instance-wrapper">
              <IcArrowDownWrapper className="ic-arrow-down-5" />
            </div>
          </div>
          <p className="text-wrapper-5">
            Chúng tôi nghiêm túc với việc bảo mật thông tin cá nhân. Hãy xem qua chính sách bảo mật của chúng tôi để
            hiểu cách thông tin của bạn được thu thập và sử dụng. Chúng tôi khuyến khích bạn chỉ chia sẻ thông tin mà
            bạn cảm thấy thoải mái khi công khai.
          </p>
          <p className="b-n-t-i-c-th-l-m-g-n">
            Bạn tôi có thể làm gần như mọi thứ miễn phí trên Companians. Tuy nhiên, có thể có những tính năng cao cấp
            hoặc sự kiện đặc biệt sẽ dành cho những thành viên&nbsp;&nbsp;trả phí, phần đặc biệt là phí sẽ rất ổn áp.
          </p>
          <p className="text-wrapper-6">
            Nếu bạn tôi gặp bất kỳ sự cố hoặc hành vi không phù hợp nào, vui lòng sử dụng tính năng báo cáo profile cá
            nhân. Chúng tôi cam kết xử lý mọi báo cáo một cách nhanh chóng và hiệu quả để bảo vệ cộng đồng của chúng
            tôi.
          </p>
          <Divider className="divider-4" />
          <p className="text-wrapper-7">
            Làm sao để tôi có thể thay đổi sở thích hoặc loại hoạt động tôi quan tâm trên Companians?
          </p>
          <div className="group-6">
            <div className="ic-arrow-down-instance-wrapper">
              <IcArrowDown className="ic-arrow-down-6" />
            </div>
          </div>
          <p className="text-wrapper-8">
            Quá dễ! Bạn tôi chỉ việc bấm vào bộ lọc và chọn sở thích ngay tại trang chính của ứng dụng. Bạn cũng có thể
            làm y hệt trong phần cài đặt hồ sơ cá nhân!
          </p>
        </div>
      </div>
      <p className="text-wrapper-9">Tạo kèo và chờ xíu thôi ; )</p>
      <p className="text-wrapper-10">
        Bạn thích đi chơi nhóm, hoặc đang ngồi 1 mình mà không biết rủ ai? Mở ngay Companians, tạo kèo đi chơi, xem ai
        hứng giống mình rồi cùng đi cho nó xôm.
      </p>
      <img className="group-7" alt="Group" src="/img/group-628870.png" />
      <div className="companians-menu">
        <div className="overlap-2">
          <div className="text-wrapper-11">Companians</div>
          <div className="overlap-3">
            <TypeGradientWrapper className="divider-5" type="line" typeLine="/img/divider.png" />
            <ButtonSpecial
              className="button-special-instance"
              contentClassName="button-special-3"
              divClassName="button-special-4"
              icPriceRight="/img/ic-16-price-right-2.svg"
              overlapGroupClassName="button-special-5"
              property1="normal"
              shadowClassName="button-special-2"
              text="Tải ứng dụng"
            />
          </div>
          <div className="frame">
            <div className="text-wrapper-12">Về chúng tôi</div>
            <div className="text-wrapper-12">Bảo mật</div>
            <div className="text-wrapper-12">Điều khoản</div>
          </div>
          <div className="frame-2">
            <IcAccount className="ic-account-instance" />
            <div className="text-wrapper-13">Đăng ký</div>
          </div>
          <img className="champagne-cheers" alt="Champagne cheers" src="/img/champagne-cheers-1.png" />
        </div>
      </div>
      <ButtonSpecial
        className="button-special-6"
        contentClassName="button-special-3"
        divClassName="button-special-4"
        icPriceRight="/img/ic-16-price-right-1.svg"
        overlapGroupClassName="button-special-7"
        property1="normal"
        shadowClassName="button-special-2"
        text="Tải ứng dụng"
      />
      <ButtonSpecial
        className="button-special-8"
        contentClassName="button-special-3"
        divClassName="button-special-4"
        icPriceRight="/img/ic-16-price-right.svg"
        overlapGroupClassName="button-special-9"
        property1="normal"
        shadowClassName="button-special-2"
        text="Tải ứng dụng"
      />
      <img className="image" alt="Image" src="/img/image-2.png" />
      <img className="image-2" alt="Image" src="/img/image-1.png" />
      <p className="text-wrapper-14">Bạn muốn đi chơi nhưng không biết rủ ai?</p>
      <p className="text-wrapper-15">Tạo kèo trong một nốt và chờ thôi!</p>
      <div className="group-8">
        <img className="image-3" alt="Image" src="/img/image.png" />
        <p className="text-wrapper-16">Bạn muốn đi ăn nhưng không biết quán nào?</p>
      </div>
    </div>
  );
};
