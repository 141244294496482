/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ButtonSpecial = ({
  property1,
  className,
  shadowClassName,
  contentClassName,
  divClassName,
  text = "Label",
  overlapGroupClassName,
  icPriceRight = "/img/ic-16-price-right-4.svg",
}) => {
  return (
    <div className={`button-special ${className}`}>
      <div className={`overlap ${property1}`}>
        {["disabled", "loading", "normal"].includes(property1) && (
          <>
            <div className={`shadow ${property1 === "normal" ? shadowClassName : undefined}`}>
              {["disabled", "loading"].includes(property1) && <>{text}</>}
            </div>
            <div className={`content ${property1 === "normal" ? contentClassName : undefined}`}>
              {property1 === "normal" && (
                <>
                  <div className={`label ${divClassName}`}>{text}</div>
                  <div className={`overlap-group ${overlapGroupClassName}`}>
                    <img className="ic-price-right" alt="Ic price right" src={icPriceRight} />
                  </div>
                </>
              )}

              {["disabled", "loading"].includes(property1) && (
                <div className="ic-loading">
                  <img
                    className="vector"
                    alt="Vector"
                    src={property1 === "loading" ? "/img/union-4.svg" : "/img/vector-2.png"}
                  />
                  {property1 === "disabled" && <img className="union" alt="Union" src="/img/union-3.svg" />}
                </div>
              )}
            </div>
          </>
        )}

        {property1 === "hover" && (
          <>
            <div className="div" />
            <div className="div" />
            <div className="content-2">
              <div className="label">{text}</div>
              <div className="ic-price-right-wrapper">
                <div className="ic-price-right-2">
                  <img className="img" alt="Vector" src="/img/vector-2.png" />
                  <img className="union" alt="Union" src="/img/union-3.svg" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ButtonSpecial.propTypes = {
  property1: PropTypes.oneOf(["disabled", "loading", "hover", "normal"]),
  text: PropTypes.string,
  icPriceRight: PropTypes.string,
};
